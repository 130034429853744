import React from "react"

import { graphql } from "gatsby"
import { useIntl } from "react-intl"

import ProfilePage from "../ProfilePage/ProfilePage"
import SEO from "~/components/Seo/Seo"
import {
  apiCreatorsToPublicUser,
  apiPublicUserToPublicUser,
  Creator,
} from "~/models/PublicUser"
import { usePublicProfileQuery } from "~/state/api/publicProfile"
import withTemplate, {
  TemplateDefaultPropTypes,
} from "~/templates/withTemplate"
import { ChefclubLocale } from "~/utils/locales"

interface PropTypes extends TemplateDefaultPropTypes {
  data: PublicProfileQuery
}

function PublicProfileSSG({ data, location }: PropTypes) {
  const intl = useIntl()
  const {
    data: userData,
    refetch,
    ...userState
  } = usePublicProfileQuery({
    userId: data.creator.userId,
    lang: intl.locale as ChefclubLocale,
  })

  const user = userData
    ? apiPublicUserToPublicUser(userData.user)
    : apiCreatorsToPublicUser(data.creator)

  return (
    <div>
      <SEO title={data.creator.username || "Anonym"} location={location} />
      <ProfilePage
        user={user}
        privacy="Public"
        ssgRecipes={data.creator.recipes}
        ssgTags={data.creator.tags}
      />
    </div>
  )
}

interface PublicProfileQuery {
  creator: Creator
}

export const query = graphql`
  query PublicProfileSSG($userId: String) {
    creator: creator(user_id: { eq: $userId }) {
      authoredRecipesCount: authored_recipes_count
      biography
      coverImageThumbnail: cover_image_thumbnail
      coverImage: cover_image
      followersCount: followers_count
      followsCount: follows_count
      instagramProfile: instagram_profile
      postsCount: posts_count
      profilePicture: profile_picture
      userId: user_id
      userTypes: user_types
      username
      recipes {
        id
        slug: url_slug
        title
        vertical
        webCover: web_cover
        webCoverThumbnail: web_cover_thumbnail
        accessMode: access_mode
        language
      }
      tags {
        slug
        translation
      }
    }
  }
`
export default withTemplate(PublicProfileSSG)
